.openTagsButton {
  width: auto !important;
}

.insertTagsContainer {
  position: relative;
}

.tagOptionsContainer {
  position: absolute;
  left: 0;
  width: auto !important;
}

.tagOption {
  width: auto !important;
}
