.emailIcon {
  margin-right: 0.5em;
}

.bulkEmailsForm {
  width: 100% !important;
}

.alert {
  width: auto !important;
}
