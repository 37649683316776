.warningModal {
  width: 100% !important;
}

.formRow {
  display: inline-block !important;
  margin-right: 16px !important;
}

.formRow > * {
  width: 125px !important;
}
